import { Component, Input, OnChanges, SimpleChanges, ViewChild, ElementRef, EventEmitter } from "@angular/core";
import { Instance } from "../../models/instance";
import { SubscriptionService } from "../../../services/subscriptionservice";

import { AuthService } from "../../../services/AuthService";

import { Apollo } from "apollo-angular";

@Component({
  selector: "uplink-detail-container",
  templateUrl: "uplink-detail-container.html",
})
export class UplinkDetailContainer implements OnChanges {
  @ViewChild("tabsnav", { read: ElementRef }) tabsnav: ElementRef;
  @ViewChild("iptabsnav", { read: ElementRef }) iptabsnav: ElementRef;
  @Input() subscription: Instance;
  @Input() bus: EventEmitter<string>;
  public loading = false;

  constructor(
    public subscriptionService: SubscriptionService,
    private auth: AuthService,
    private apollo: Apollo,
  ) {}

  ngOnChanges(changes: SimpleChanges) {}
}
