import { Component, OnInit } from "@angular/core";
import { MatomoTracker } from "ngx-matomo";
@Component({
  selector: "page-about",
  templateUrl: "about.html",
})
export class AboutPage implements OnInit {
  constructor(private matomo: MatomoTracker) {
    matomo.setDocumentTitle("About");
  }
  ngOnInit() {
    this.matomo.trackPageView();
  }
}
