import { Component, EventEmitter, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MatomoTracker } from "ngx-matomo";
import { AuthService } from "../../services/AuthService";
import { ExtendedServicePortSubscriptionType } from "../../helpers/types";
import { Apollo, gql } from "apollo-angular";
import { getDetailRouterLink } from "../../helpers/getDetailRouterLink";

/** Class/page only serves to detect which subscription type we have and forward to the right detail page */

const FIND_SUBSCRIPTION_TAG = gql`
  query FindSubscriptionTag($subscriptionId: ID!) {
    subscription(id: $subscriptionId) {
      subscriptionId
      product {
        tag
        type
      }
    }
  }
`;

interface SubscriptionTagType {
  subscription: {
    subscriptionId: string;
    product: {
      tag: string;
      type: string;
    };
  };
}

@Component({
  selector: "page-subscription-detail-redirection",
  templateUrl: "subscription-detail-redirection.html",
})
export class SubscriptionDetailRedirectionPage implements OnInit {
  public subscriptionId: string;
  public productType: string;
  public surfDescription: string;
  public isTerminated = false;
  public pageTitle = "";
  public messageBus: EventEmitter<string> = new EventEmitter();
  public baseSubscription: ExtendedServicePortSubscriptionType;

  constructor(
    private matomo: MatomoTracker,
    private router: Router,
    private route: ActivatedRoute,
    private auth: AuthService,
    private apollo: Apollo,
  ) {
    matomo.setDocumentTitle("SubscriptionDetail (Replicated)");
  }

  ngOnInit() {
    this.matomo.trackPageView();
    this.auth.userLoaded.subscribe(() => {
      this.init();
    });
    if (!this.auth.state.loading) {
      this.init();
    }
  }

  async init() {
    this.subscriptionId = this.route.snapshot.paramMap.get("subscriptionId");
    this.productType = this.route.snapshot.paramMap.get("productType");

    this.queryBaseSubscription();
  }

  async queryBaseSubscription() {
    this.apollo
      .query<SubscriptionTagType>({
        query: FIND_SUBSCRIPTION_TAG,
        variables: {
          subscriptionId: this.subscriptionId,
        },
        errorPolicy: "all",
      })
      .subscribe((result) => {
        const subscription = result.data.subscription;
        const link = getDetailRouterLink(subscription, subscription.product.type);
        this.router.navigate([link]);
      });
  }
}
