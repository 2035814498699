import { Component, OnInit } from "@angular/core";
import { MatomoTracker } from "ngx-matomo";
@Component({
  selector: "page-notfound",
  templateUrl: "notfound.html",
})
export class NotfoundPage implements OnInit {
  constructor(private matomo: MatomoTracker) {
    this.matomo.setDocumentTitle("Not found");
  }

  ngOnInit() {
    this.matomo.trackPageView();
  }
}
