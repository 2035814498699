import { Component } from "@angular/core";
import { AuthService } from "../../../../services/AuthService";
import { ApiHelper } from "../../../../helpers/apihelper";
import { GenericSubscriptionComponent } from "../generic-subscription";
import {
  MscSubscriptionDetailsGQL,
  MscSubscriptionDetailsQuery,
  MscSubscriptionDetailsType,
} from "../../../../gql/generated-ingestor";
import { ActivatedRoute } from "@angular/router";
import { ApolloQueryResult } from "@apollo/client";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "msc-subscription",
  templateUrl: "msc-subscription.html",
})
export class MscSubscriptionComponent extends GenericSubscriptionComponent<
  MscSubscriptionDetailsGQL,
  MscSubscriptionDetailsQuery,
  MscSubscriptionDetailsType
> {
  // possibly move these to a superclass for these components?
  public impactSetting = "Never";
  public linkPortSubscription = true;

  constructor(
    protected api: ApiHelper,
    protected auth: AuthService,
    protected dialog: MatDialog,
    protected route: ActivatedRoute,
    protected query: MscSubscriptionDetailsGQL,
    protected translate: TranslateService,
  ) {
    super(api, auth, dialog, route, translate);
    this.subscriptionChange.subscribe((s) => this.onSubscriptionChange(s));
  }

  protected onQuerySuccess(result: ApolloQueryResult<MscSubscriptionDetailsQuery>): MscSubscriptionDetailsType {
    return result.data.mscDetails as MscSubscriptionDetailsType;
  }

  private onSubscriptionChange(subscription: MscSubscriptionDetailsType) {
    this.linkPortSubscription =
      subscription !== undefined && subscription.portSubscription.customerId === subscription.customerId;
  }
}
