import {
  LightpathSubscriptionDetailsQuery,
  LightpathRedundantSubscriptionDetailsQuery,
  WirelessSubscriptionDetailsQuery,
  FirewallSubscriptionDetailsQuery,
  AggspSubscriptionDetailsQuery,
  L2vpnSubscriptionDetailsQuery,
  L3vpnSubscriptionDetailsQuery,
  IpBgpSubscriptionDetailsQuery,
  IpStaticSubscriptionDetailsQuery,
  PortSubscriptionDetailsQuery,
  MscSubscriptionDetailsQuery,
} from "../../gql/generated-ingestor";

export type AggspSubscriptionDetails = AggspSubscriptionDetailsQuery["aggspDetails"]; // Not used
export type ServicePortSubscriptionDetails = PortSubscriptionDetailsQuery["servicePortDetails"];
export type MscSubscriptionDetails = MscSubscriptionDetailsQuery["mscDetails"];
export type IpBgpSubscriptionDetails = IpBgpSubscriptionDetailsQuery["ipBgpDetails"];
export type IpStaticSubscriptionDetails = IpStaticSubscriptionDetailsQuery["ipStaticDetails"];
export type L2vpnSubscriptionDetails = L2vpnSubscriptionDetailsQuery["l2vpnDetails"];
export type L3vpnSubscriptionDetails = L3vpnSubscriptionDetailsQuery["l3vpnDetails"];
export type LightPathSubscriptionDetails = LightpathSubscriptionDetailsQuery["lpDetails"];
export type LightPathRedundantSubscriptionDetails = LightpathRedundantSubscriptionDetailsQuery["lrDetails"];
export type WirelessSubscriptionDetails = WirelessSubscriptionDetailsQuery["wirelessDetails"];
export type FirewallSubscriptionDetails = FirewallSubscriptionDetailsQuery["firewallDetails"];

export type IpSubscriptionDetails = IpBgpSubscriptionDetails | IpStaticSubscriptionDetails;
export type LightPathBothSubscriptionDetails = LightPathSubscriptionDetails | LightPathRedundantSubscriptionDetails;
export type PortSubscriptionDetails =
  | AggspSubscriptionDetails
  | ServicePortSubscriptionDetails
  | MscSubscriptionDetails;

export const isFirewall = (s): s is FirewallSubscriptionDetails => s.product.type === "FW";
export const isIpBgp = (s): s is IpBgpSubscriptionDetails => s.product.type === "IP" && s.product.tag === "IPBGP";
export const isIp = (s): s is IpSubscriptionDetails => s.product.type === "IP";
export const isL2vpn = (s): s is L2vpnSubscriptionDetails => s.product.type === "L2VPN";
export const isL3vpn = (s): s is L3vpnSubscriptionDetails => s.product.type === "L3VPN";
export const isLightPath = (s): s is LightPathBothSubscriptionDetails => s.product.type === "LightPath";
export const isPort = (s): s is PortSubscriptionDetails => s.product.type === "Port";

export const canHaveFirewall = (
  s,
): s is PortSubscriptionDetails | L2vpnSubscriptionDetails | L3vpnSubscriptionDetails =>
  isPort(s) || isL2vpn(s) || isL3vpn(s);
