import { Component, OnInit, AfterViewInit } from "@angular/core";
import { MatomoTracker } from "ngx-matomo";
import { ApiHelper } from "../../helpers/apihelper";
import { TranslatePipe } from "@ngx-translate/core";

@Component({
  selector: "page-support",
  templateUrl: "support.html",
})
export class SupportPage implements AfterViewInit {
  constructor(
    private api: ApiHelper,
    private matomo: MatomoTracker,
  ) {
    matomo.setDocumentTitle("Support");
  }

  ngAfterViewInit() {
    this.matomo.trackPageView();
  }
}
