import { Component, OnInit } from "@angular/core";
import { MatomoTracker } from "ngx-matomo";
@Component({
  selector: "page-disclaimer",
  templateUrl: "disclaimer.html",
})
export class DisclaimerPage implements OnInit {
  constructor(private matomo: MatomoTracker) {
    this.matomo.setDocumentTitle("Disclaimer");
  }
  ngOnInit() {
    this.matomo.trackPageView();
  }
}
