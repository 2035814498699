// This file informs Angular that there is a global variable called ENV
// available and that it is structured as below. The actual contents are
// provided by assets/env.js (when not inside nginx) or nginx (when served
// by nginx, see default.conf).

// This struct is never valid; it is only defined to allow Karma to execute
// the Angular tests.
const noUseStruct = {
  DOMAINS_ENABLED: false,
  FILTER_ACTIVE_MESSAGES: false,
  NWD_API_BASE_URL: "http://mockApi/",
  REPLICATION_ENABLED: false,
  WIRELESS_ENABLED: false,
  SELFSERVICE_ENABLED: false,
};

export const ENV: {
  DOMAINS_ENABLED: boolean;
  DOMAINS_API: string;
  FILTER_ACTIVE_MESSAGES: boolean;
  NWD_API_BASE_URL: string;
  REPLICATION_ENABLED: boolean;
  WIRELESS_ENABLED: boolean;
  SELFSERVICE_ENABLED: boolean;
} = (window as any).ENV ?? noUseStruct;

export const apiUrl = (path: string): string => ENV.NWD_API_BASE_URL + path;
