import { Component, OnInit } from "@angular/core";
import { MatomoTracker } from "ngx-matomo";
@Component({
  selector: "page-forbidden",
  templateUrl: "forbidden.html",
})
export class ForbiddenPage implements OnInit {
  constructor(private matomo: MatomoTracker) {
    this.matomo.setDocumentTitle("Error");
  }

  ngOnInit() {
    this.matomo.trackPageView();
  }
}
