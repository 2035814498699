import { Component, Inject } from "@angular/core";
import { MatomoInjector } from "ngx-matomo";
import { TranslateService } from "@ngx-translate/core";
import { MatomoConfig } from "../helpers/matomoconfig";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "Surfnet";
  constructor(
    @Inject(MatomoInjector) private matomoInjector: MatomoInjector,
    @Inject(TranslateService) translate: TranslateService,
  ) {
    // this.matomoInjector.init(MatomoConfig.url, MatomoConfig.site_id);

    translate.addLangs(["nl", "en"]);
    // this language will be used as a fallback when a translation isn't found in the current language
    const currentLanguage = translate.getBrowserLang();
    translate.setDefaultLang("nl");

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    const storedLanguage = localStorage.getItem("lang");
    translate.use(storedLanguage !== null ? storedLanguage : currentLanguage);
  }
}
