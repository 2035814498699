import { CustomerDescription } from "../../gql/generated";
import { transform } from "../../helpers/subscription/l2vpn";
import { Instance } from "./instance";

export class L2VPNInstance extends Instance {
  customerDescriptions: CustomerDescription[];
  customerId: string;
  description: string;
  domain: string;
  name: string;
  subscriptionId: string;
  l3vpnsapss: any[];

  _saps: any[];
  _ownPoints: any[];

  _pairs: any[];
  _otherPortsCount: 0;
  _circuits: any[];

  constructor(data?: any) {
    super();
    const details = transform(data);
    Object.entries(details).forEach((entry) => {
      this[entry[0]] = entry[1];
    });
    this.customerDescriptions = data.customerDescriptions;
  }
  get sapPairs(): any[] {
    return this._pairs;
  }

  get ownPoints(): any[] {
    return this._ownPoints;
  }

  get circuits() {
    return this._circuits;
  }

  set circuits(c: any[]) {
    this._circuits = c;
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  get saps(): any {
    return this._saps;
  }
  set saps(ports: any) {
    this._saps = [];
    this._pairs = [];
    this._otherPortsCount = 0;

    for (const port of ports) {
      if (port.port?.customerId === this.customerId) {
        this._saps.unshift(port);
      } else {
        this._saps.push(port);
        this._otherPortsCount++;
      }
    }

    for (const sap of this._saps) {
      const isOwnPort = sap.port?.customerId === this.customerId;

      if (isOwnPort && this._otherPortsCount === 0) {
        // if the lightpath only has ports of the current customer,
        // alternate them left and right.
        // the default preference is to show own ports on the left side.
        let isAdded = false;
        for (const p in this._pairs) {
          if (this._pairs[p].length === 1) {
            this._pairs[p][1] = sap;
            isAdded = true;
            break;
          }
        }
        if (!isAdded) {
          this._pairs.push([sap]);
        }
      } else {
        if (isOwnPort) {
          this._pairs.push([sap]);
        } else {
          let added = false;
          for (const p in this._pairs) {
            if (this._pairs[p].length === 1) {
              this._pairs[p][1] = sap;
              added = true;
              break;
            }
          }
          if (!added) {
            this._pairs.push([, sap]);
          }
        }
      }
    }
  }
}
