/* eslint-disable @typescript-eslint/member-ordering */
import { of as observableOf } from "rxjs";
import { Injectable, Output, EventEmitter, Directive } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import "rxjs";

import { Configuration } from "../components/models/configuration";
import { CurrentUser } from "../components/models/currentuser";
import { Customer } from "../components/models/customer";
import { Product } from "../components/models/product";
import { LightPathInstance } from "../components/models/lightpathinstance";
import { PortInstance } from "../components/models/portinstance";
import { L2VPNInstance } from "../components/models/l2vpninstance";
import { HealthIndicator } from "../components/models/HealthIndicator";
import { SurfNotification } from "../components/models/notification";
import { UserSettings } from "../components/models/UserSettings";
import { StorageHelper } from "../helpers/storage/storagehelper";
import { map, tap } from "rxjs/operators";
import { SelfServiceCommand } from "./self-service/self-service-command";
import { ApiTokenOverrides, ModifyResponse } from "./self-service/models/types";
import { FirewallInstance } from "../components/models/firewallinstance";
import { WirelessInstance } from "../components/models/wirelessinstance";
import { ipamSettings } from "../components/models/ipamSettings";
import { AutoMitigation } from "../components/models/automitigation";
import { DdosFilteredTraffic, SubscriptionMessage } from "./types";
import { CacheService } from "../services/cacheservice";
import { nfvStats } from "../components/models/nfv_stats";
import { apiUrl } from "../app/app.runtime";
import { Apollo } from "apollo-angular";

@Directive()
@Injectable()
export class ApiHelper {
  static started = 0;
  @Output() loadingEvent: EventEmitter<any> = new EventEmitter();
  @Output() errorEvent: EventEmitter<any> = new EventEmitter();
  configuration: any = {};

  constructor(
    public http: HttpClient,
    private cache: CacheService,
    public apollo: Apollo,
  ) {}

  get xhrCallsStarted(): number {
    return ApiHelper.started;
  }

  /**
   * createFetchOptions():object {} along with options = {observe: 'body'}
   * makes this.http return an object of <T> instead of HttpEvent<T>
   */
  createFetchOptions(
    options = {},
    headers = {},
    responseType = "json",
    overrides?: ApiTokenOverrides,
    contentType = "application/json",
  ): object {
    const overrideToken = overrides !== undefined ? overrides.access_token : null;
    const access_token = overrideToken || localStorage.getItem("access_token");

    let shouldIntercept = true;
    if (options && options["intercept"] === false) {
      shouldIntercept = false;
    }

    let contentHeaders: HttpHeaders;
    if (contentType === "") {
      contentHeaders = new HttpHeaders({
        Authorization: `bearer ${access_token}`,
        "x-ignore-interceptor": shouldIntercept === false ? "1" : "0",
      });
    } else {
      contentHeaders = new HttpHeaders({
        Authorization: `bearer ${access_token}`,
        "Content-Type": `${contentType}`,
        "x-ignore-interceptor": shouldIntercept === false ? "1" : "0",
      });
    }

    // const contentHeaders = new HttpHeaders();
    const fetchOptions = Object.assign({}, { params: {}, headers: contentHeaders, responseType }, options, {
      credentials: "same-origin",
      redirect: "manual",
      observe: "body",
    });
    ++ApiHelper.started;
    return fetchOptions;
  }

  isEmpty(obj: any) {
    for (const x in obj) {
      return false;
    }
    return true;
  }

  pushError(obj: any) {
    const targetUrl = apiUrl("user/error");
    const fetchOptions = this.createFetchOptions();
    this.http.post(targetUrl, obj, fetchOptions).subscribe(
      (res) => {},
      (error) => {},
    );
  }

  /**
   * Unsecured endpoint. This contains the config
   * with among others the authorization URL.
   */
  async config() {
    const targetUrl = apiUrl("user/config");
    const fetchOptions = this.createFetchOptions();

    if (this.isEmpty(this.configuration)) {
      return this.http
        .get<Configuration>(targetUrl)
        .pipe(
          map((conf) => {
            this.configuration = Object.assign(new Configuration(), conf);
            return this.configuration;
          }),
        )
        .toPromise();
    } else {
      return Promise.resolve(this.configuration);
    }
  }

  /**
   * The currently logged in user.
   */
  me() {
    const targetUrl = apiUrl("user/me");
    const fetchOptions = this.createFetchOptions({ observe: "body" });
    return this.http
      .get<CurrentUser>(targetUrl, fetchOptions)
      .pipe(map((data) => Object.assign(new CurrentUser(), data)))
      .toPromise();
  }

  settings() {
    const targetUrl = apiUrl("user/settings");
    const fetchOptions = this.createFetchOptions({ observe: "body" });
    return this.http
      .get<UserSettings>(targetUrl, fetchOptions)
      .pipe(map((data) => Object.assign(new UserSettings(), data)))
      .toPromise();
  }

  updateSettings(params: any) {
    const targetUrl = apiUrl("user/settings");
    const fetchOptions = this.createFetchOptions({ observe: "body" });
    return this.http
      .put<UserSettings>(targetUrl, params, fetchOptions)
      .pipe(
        map((data) => {
          console.log(data);
          return Object.assign(new UserSettings(), data);
        }),
      )
      .toPromise();
  }

  /**
   *
   * @param items An array of items.
   * @param fn A function that accepts an item from the array and returns a promise.
   * @returns Promise
   */
  forEachPromise(items: any[], fn) {
    return items.reduce((promise, item) => promise.then(() => fn(item)), Promise.resolve());
  }

  getWithCache(url: string, options: any = {}) {
    const cached = this.cache.get(url);
    if (cached === null) {
      return this.http
        .get(url, options)
        .pipe(
          map((data) => {
            this.cache.set(url, data);
            return data;
          }),
        )
        .toPromise();
    }
    return Promise.resolve(cached);
  }

  subscriptionsOfType(customerGUID: string, productType: string) {
    const targetUrl = apiUrl(`subscriptions/productType/${productType}/customerId/${customerGUID}`);
    const fetchOptions = this.createFetchOptions({
      observe: "body",
    });
    return this.http.get(targetUrl, fetchOptions).toPromise();
  }

  subscriptionCounts(customerGUID: string, productType: string, options: any = {}) {
    const targetUrl = apiUrl(`subscriptions/counts/${productType}/${customerGUID}`);
    const fetchOptions = this.createFetchOptions({ observe: "body" });
    fetchOptions["params"] = options;
    // return this.http.get(targetUrl, fetchOptions).toPromise();
    return this.getWithCache(targetUrl, fetchOptions);
  }

  /**
   * Active products for the current user
   */
  products() {
    const targetUrl = apiUrl("user/me");
    const fetchOptions = this.createFetchOptions({
      observe: "body",
    });
    return this.http.get<Product[]>(targetUrl, fetchOptions).toPromise();
  }

  /**
   * Retrieve SLS/SLA information for a specific service.
   *
   * @param string subscriptionId
   */
  sls(subscriptionId: string, year?: number, month?: number) {
    if (year === undefined) {
      year = new Date().getFullYear();
    }
    if (month === undefined) {
      month = new Date().getMonth();
    }
    const targetUrl = apiUrl(`stats/sls/${subscriptionId}/${year}/${month}`);
    const fetchOptions = this.createFetchOptions({
      observe: "body",
      intercept: false,
    });
    return this.http.get<any>(targetUrl, fetchOptions).toPromise();
  }

  sls_report(subscriptionId: string) {
    const targetUrl = apiUrl(`stats/sls/report/${subscriptionId}`);
    const fetchOptions = this.createFetchOptions({
      observe: "body",
      intercept: false,
    });
    return this.http.get<any>(targetUrl, fetchOptions).toPromise();
  }

  service_stats_aggregated(
    subscriptionId: string,
    subscriptionInstanceId: string,
    chartDataModus: string,
    packetSubFilter: string,
    start: Date,
    end: Date,
  ) {
    let offsetUrlPart = "";
    if (start) {
      const deltaMinutes = Math.round((end.getTime() - start.getTime()) / 1000 / 60);
      const step = this.convertStep(deltaMinutes);
      const startDate = this.snapDate(start, step);
      const endDate = this.snapDate(end, step);

      offsetUrlPart = `?start=${startDate}&end=${endDate}&step=${step}`;
    }

    let endPointUrl = "";
    if (chartDataModus === "packets") {
      endPointUrl = "stats/packets/" + subscriptionId;
      offsetUrlPart = offsetUrlPart + `&packetType=${packetSubFilter}`;
    } else {
      endPointUrl = "stats/" + subscriptionId;
    }

    if (subscriptionInstanceId !== "") {
      endPointUrl += "/" + subscriptionInstanceId;
    }
    const targetUrl = apiUrl(endPointUrl + offsetUrlPart);

    const convert_to_ms = (item) => {
      item[0] = item[0] * 1000;
      return item;
    };

    return this.http
      .get<any>(targetUrl, this.createFetchOptions({ intercept: false }))
      .pipe(
        map((data) => {
          if (chartDataModus === "packets") {
            const rv = {
              multicast: {
                in: data.broadcast.hasOwnProperty("in") ? data["multicast"]["in"].map(convert_to_ms) : [],
                out: data["multicast"]["out"].map(convert_to_ms),
                spike_in: data["multicast"].hasOwnProperty("in") ? data["multicast"]["peakIn"].map(convert_to_ms) : [],
                spike_out: data["multicast"]["peakOut"].map(convert_to_ms),
              },
              broadcast: {
                in: data.broadcast.hasOwnProperty("in") ? data["broadcast"]["in"].map(convert_to_ms) : [],
                out: data["broadcast"]["out"].map(convert_to_ms),
                spike_in: data["broadcast"].hasOwnProperty("in") ? data["broadcast"]["peakIn"].map(convert_to_ms) : [],
                spike_out: data["broadcast"]["peakOut"].map(convert_to_ms),
              },
              unicast: {
                in: data.broadcast.hasOwnProperty("in") ? data["unicast"]["in"].map(convert_to_ms) : [],
                out: data["unicast"]["out"].map(convert_to_ms),
                spike_in: data["unicast"].hasOwnProperty("in") ? data["unicast"]["peakIn"].map(convert_to_ms) : [],
                spike_out: data["unicast"]["peakOut"].map(convert_to_ms),
              },
              error: false,
            };
            return rv;
          } else {
            const rv = {
              in: data.hasOwnProperty("in") ? data["in"].map(convert_to_ms) : [],
              out: data["out"].map(convert_to_ms),
              spike_in: data.hasOwnProperty("in") ? data["peakIn"].map(convert_to_ms) : [],
              spike_out: data["peakOut"].map(convert_to_ms),
              error: false,
            };
            return rv;
          }
        }),
      )
      .toPromise()
      .catch((err) =>
        // Do messaging and error handling here
        ({ in: [], out: [], error: true }),
      );
  }

  /**
   * Retrieve aggregated stats for a subscription
   */
  wireless_stats_aggregated(subscriptionId: string, kpiType: string, frequency: string, start: Date, end: Date) {
    let offsetUrlPart = `?frequency=${frequency}&raw=false`;
    if (start) {
      const deltaMinutes = Math.round((end.getTime() - start.getTime()) / 1000 / 60);
      const step = this.convertStep(deltaMinutes);
      const startDate = this.snapDate(start, step);
      const endDate = this.snapDate(end, step);

      offsetUrlPart += `&start=${startDate}&end=${endDate}&step=${step}`;
    }

    let endPointUrl = "stats/wireless/" + subscriptionId + "/" + kpiType;

    const targetUrl = apiUrl(endPointUrl + offsetUrlPart);

    const convert_to_ms = (item) => {
      item[0] = item[0] * 1000;
      return item;
    };

    return this.http
      .get<any>(targetUrl, this.createFetchOptions({ intercept: false }))
      .pipe(
        map((data) => {
          const rv = {
            five: data.hasOwnProperty("five") ? data["five"].map(convert_to_ms) : [],
            twofour: data.hasOwnProperty("twofour") ? data["twofour"].map(convert_to_ms) : [],
            error: false,
          };
          return rv;
        }),
      )
      .toPromise()
      .catch((err) =>
        // Do messaging and error handling here
        ({ five: [], twofour: [], error: true }),
      );
  }

  /**
   * Retrieve aggregated stats for a subscription
   */
  firewall_stats_aggregated(
    subscriptionId: string,
    filter_option: string,
    chartDataModus: string,
    start: Date,
    end: Date,
  ) {
    let offsetUrlPart = `?filter_option=${filter_option}`;
    if (start) {
      const deltaMinutes = Math.round((end.getTime() - start.getTime()) / 1000 / 60);
      const step = this.convertStep(deltaMinutes);
      const startDate = this.snapDate(start, step);
      const endDate = this.snapDate(end, step);

      offsetUrlPart += `&start=${startDate}&end=${endDate}&step=${step}`;
    }

    let endPointUrl = "";
    if (chartDataModus === "packets") {
      endPointUrl = "stats/firewall/packets/" + subscriptionId;
    } else {
      endPointUrl = "stats/firewall/" + subscriptionId;
    }

    const targetUrl = apiUrl(endPointUrl + offsetUrlPart);

    const convert_to_ms = (item) => {
      item[0] = item[0] * 1000;
      return item;
    };

    return this.http
      .get<any>(targetUrl, this.createFetchOptions({ intercept: false }))
      .pipe(
        map((data) => {
          if (chartDataModus === "packets") {
            const rv = {
              multicast: {
                in: data.broadcast.hasOwnProperty("in") ? data["multicast"]["in"].map(convert_to_ms) : [],
                out: data["multicast"]["out"].map(convert_to_ms),
                spike_in: data["multicast"].hasOwnProperty("in") ? data["multicast"]["peakIn"].map(convert_to_ms) : [],
                spike_out: data["multicast"]["peakOut"].map(convert_to_ms),
              },
              broadcast: {
                in: data.broadcast.hasOwnProperty("in") ? data["broadcast"]["in"].map(convert_to_ms) : [],
                out: data["broadcast"]["out"].map(convert_to_ms),
                spike_in: data["broadcast"].hasOwnProperty("in") ? data["broadcast"]["peakIn"].map(convert_to_ms) : [],
                spike_out: data["broadcast"]["peakOut"].map(convert_to_ms),
              },
              unicast: {
                in: data.broadcast.hasOwnProperty("in") ? data["unicast"]["in"].map(convert_to_ms) : [],
                out: data["unicast"]["out"].map(convert_to_ms),
                spike_in: data["unicast"].hasOwnProperty("in") ? data["unicast"]["peakIn"].map(convert_to_ms) : [],
                spike_out: data["unicast"]["peakOut"].map(convert_to_ms),
              },
              error: false,
            };
            return rv;
          } else {
            const rv = {
              in: data.hasOwnProperty("in") ? data["in"].map(convert_to_ms) : [],
              out: data["out"].map(convert_to_ms),
              spike_in: data.hasOwnProperty("in") ? data["peakIn"].map(convert_to_ms) : [],
              spike_out: data["peakOut"].map(convert_to_ms),
              error: false,
            };
            return rv;
          }
        }),
      )
      .toPromise()
      .catch((err) =>
        // Do messaging and error handling here
        ({ in: [], out: [], error: true }),
      );
  }

  /**
   * Retrieve NFV stats
   */
  getNfvStats(subscriptionId: string, start: Date, end: Date): Promise<nfvStats> {
    let offsetUrlPart = "";
    if (start) {
      const deltaMinutes = Math.round((end.getTime() - start.getTime()) / 1000 / 60);
      const step = this.convertStep(deltaMinutes);
      const startDate = this.snapDate(start, step);
      const endDate = this.snapDate(end, step);
      offsetUrlPart += `?start=${startDate}&end=${endDate}&step=${step}`;
    } else {
      console.warn("Retrieving NFV stats without start date despite typing indicating otherwise.");
    }

    const endPointUrl = "stats/nfv/" + subscriptionId;
    const targetUrl = apiUrl(endPointUrl + offsetUrlPart);

    return this.http
      .get(targetUrl, this.createFetchOptions({ intercept: false }))
      .toPromise()
      .then((data) => {
        // TODO Test
        const toMilliseconds = (item: number[]): number[] => item.map((v) => v * 1000);
        const rv: nfvStats = {
          cpuUsage: data["cpu_usage"].map(toMilliseconds),
          memoryUsage: data["memory_usage"].map(toMilliseconds),
          sessionCount: data["session_count"].map(toMilliseconds),
          sessionRate: data["session_rate"].map(toMilliseconds),
        };
        return rv;
      });
  }

  total_stats_aggregated(subscriptionType: string, startOffsetMinutes: number) {
    let offsetUrlPart = "";
    const customerGUID = StorageHelper.currentUser;
    if (startOffsetMinutes !== -1) {
      const step = this.convertStep(startOffsetMinutes);
      const start = this.convertStart(startOffsetMinutes, step);
      offsetUrlPart = `?start=${start}&step=${step}`;
    }
    const targetUrl = apiUrl(`stats/${subscriptionType}/${customerGUID}${offsetUrlPart}`);

    const convert_to_ms = (item) => {
      item[0] = item[0] * 1000;
      return item;
    };
    return this.http
      .get<any>(targetUrl, this.createFetchOptions({ intercept: false }))
      .pipe(
        map((data) => {
          const rv = {
            in: data.hasOwnProperty("in") ? data["in"].map(convert_to_ms) : [],
            out: data["out"].map(convert_to_ms),
            label: subscriptionType,
          };
          return rv;
        }),
        (err) =>
          // Do messaging and error handling here
          observableOf({ in: [], out: [] }),
      )
      .toPromise();
  }

  performance_stats(subscriptionId: string, startOffsetMinutes: number) {
    let offsetUrlPart = "";
    if (startOffsetMinutes !== -1) {
      const step = this.convertStep(startOffsetMinutes);
      const start = this.convertStart(startOffsetMinutes, step);
      offsetUrlPart = `?start=${start}&step=${step}`;
    }
    const targetUrl = apiUrl("stats/performance/" + subscriptionId + offsetUrlPart);

    const convert_to_ms = (item) => {
      item[0] = item[0] * 1000;
      // delay and jitter are measured in nanoseconds.
      item[1] = item[1] / 1000;
      return item;
    };
    return this.http
      .get<any>(targetUrl, this.createFetchOptions({ intercept: false }))
      .pipe(
        map((data) => {
          const rv = {
            delay: data["delay"].map(convert_to_ms),
            jitter: data["jitter"].map(convert_to_ms),
          };
          return rv;
        }),
        (err) =>
          // Do messaging and error handling here
          observableOf({ in: [], out: [] }),
      )
      .toPromise();
  }

  topology(subscriptionId: string) {
    const targetUrl = apiUrl("subscriptions/topology/" + subscriptionId);

    return this.http
      .get<any>(targetUrl, this.createFetchOptions())
      .pipe(map(({ data }) => data))
      .toPromise();
  }

  convertStep(deltaMinutes) {
    const offsetMinutesToStep = {
      2880: 300,
      20160: 3600,
      86400: 14400,
      259200: 28800,
    }; // > 2days, 2 weeks, 2 months, 6 months
    let step = 60; // default for the first 2 days

    for (const key in offsetMinutesToStep) {
      if (deltaMinutes >= parseInt(key)) {
        step = offsetMinutesToStep[key];
      }
    }

    return step;
  }

  snapDate(date, step) {
    let start = Math.round(date.getTime() / 1000);
    start = start - (start % step);
    return start;
  }

  convertStart(startOffsetMinutes, step) {
    const newDateObj = new Date();
    newDateObj.setTime(newDateObj.getTime() - startOffsetMinutes * 60 * 1000);
    let start = Math.round(newDateObj.getTime() / 1000);
    start = start - (start % step);

    return start;
  }

  /**
   * Retrieve lightpath details by subscriptionId
   */
  lightpath_details(subscriptionId: string) {
    const targetUrl = apiUrl("subscriptions/LightPath/" + subscriptionId);
    const fetchOptions = this.createFetchOptions();
    return this.http
      .get<LightPathInstance>(targetUrl, fetchOptions)
      .pipe(
        map((data) => {
          const result = Object.assign(new LightPathInstance(), data);
          result.subscriptionId = subscriptionId;
          result.postProcessData();
          return result;
        }),
      )
      .toPromise();
  }

  /**
   * Retrieve wireless details by subscriptionId
   */
  wireless_details(subscriptionId: string) {
    const targetUrl = apiUrl("subscriptions/wireless/" + subscriptionId);
    const fetchOptions = this.createFetchOptions();
    return this.http
      .get<WirelessInstance>(targetUrl, fetchOptions)
      .pipe(
        map((data) => {
          const result = Object.assign(new WirelessInstance(), data);
          result.subscriptionId = subscriptionId;
          return result;
        }),
      )
      .toPromise();
  }

  /**
   * Retrieve wireless details by subscriptionId
   */
  wireless_health_details(subscriptionId: string) {
    const targetUrl = apiUrl("health/wireless/" + subscriptionId + "/detail");
    const fetchOptions = this.createFetchOptions();
    return this.http
      .get<HealthIndicator>(targetUrl, fetchOptions)
      .pipe(
        map((data) => {
          const result = Object.assign(new HealthIndicator(), data);
          return result;
        }),
      )
      .toPromise();
  }

  /**
   * Retrieve wireless KPI stats for list page by subscriptionId
   */
  wireless_list_kpi(subscriptionId: string, countNumAccessPoints: boolean): Promise<HealthIndicator> {
    const targetUrl = apiUrl("health/wireless/" + subscriptionId);
    let fetchOptions = this.createFetchOptions();
    fetchOptions["params"]["count_num_access_points"] = countNumAccessPoints;
    return this.http
      .get<HealthIndicator>(targetUrl, fetchOptions)
      .pipe(
        map((data) => {
          const result = Object.assign(new HealthIndicator(), data);
          return result;
        }),
      )
      .toPromise();
  }

  /**
   * Retrieve wireless details by subscriptionId
   */
  wireless_accesspoint_details(subscriptionId: string) {
    const targetUrl = apiUrl("subscriptions/Wireless/" + subscriptionId + "/accesspoint");
    const fetchOptions = this.createFetchOptions();
    return this.http
      .get<WirelessInstance>(targetUrl, fetchOptions)
      .pipe(
        map((data) => {
          const result = Object.assign(new WirelessInstance(), data);
          result.subscriptionId = subscriptionId;
          return result;
        }),
      )
      .toPromise();
  }

  ip_prefix(subscriptionId: string) {
    const targetUrl = apiUrl(`subscriptions/IP_PREFIX/${subscriptionId}`);
    const fetchOptions = this.createFetchOptions({
      observe: "body",
    });
    return this.http.get(targetUrl, fetchOptions).toPromise();
  }

  /**
   * Retrieve port details by subscriptionId
   */
  port_details(subscriptionId: string) {
    const targetUrl = apiUrl("subscriptions/Port/" + subscriptionId);
    const fetchOptions = this.createFetchOptions();
    return this.http
      .get<PortInstance>(targetUrl, fetchOptions)
      .pipe(
        map((data) => {
          const result = Object.assign(new PortInstance(), data);
          result.subscriptionId = subscriptionId;

          // this.service_stats_aggregated(subscriptionId, '', 1440).then(chartData => {
          //     this.loadingEvent.emit({id: 'finish_aggregated_stats', subscriptionId: subscriptionId, data: chartData});
          // });

          return result;
        }),
      )
      .toPromise();
  }

  l2vpn_details(subscriptionId: string) {
    const targetUrl = apiUrl("subscriptions/L2VPN/" + subscriptionId);
    const fetchOptions = this.createFetchOptions();
    return this.http
      .get<L2VPNInstance>(targetUrl, fetchOptions)
      .pipe(
        map((data) => {
          const result = Object.assign(new L2VPNInstance(), data);
          result.subscriptionId = subscriptionId;
          // console.log(result);
          return result;
        }),
      )
      .toPromise();
  }

  l3vpn_details(subscriptionId: string) {
    const targetUrl = apiUrl("subscriptions/L3VPN/" + subscriptionId);
    const fetchOptions = this.createFetchOptions(targetUrl);
    return this.http
      .get<L2VPNInstance>(targetUrl, fetchOptions)
      .pipe(
        map((data) => {
          const result = Object.assign(new L2VPNInstance(), data);
          result.subscriptionId = subscriptionId;
          // console.log(result);
          return result;
        }),
      )
      .toPromise();
  }

  fw_details(subscriptionId: string) {
    const targetUrl = apiUrl("subscriptions/FW/" + subscriptionId);
    const fetchOptions = this.createFetchOptions();
    return this.http
      .get<FirewallInstance>(targetUrl, fetchOptions)
      .pipe(
        map((data) =>
          // const result = Object.assign(new FirewallInstance(), data);
          // const result = new FirewallInstance(data as any);

          // result.subscriptionId = subscriptionId;
          // console.log(result);
          // return result;
          ({
            ...data,
            subscriptionId,
          }),
        ),
      )
      .toPromise();
  }

  raw_details(productType: string, subscriptionId: string) {
    const targetUrl = apiUrl(`subscriptions/${productType}/${subscriptionId}`);
    const fetchOptions = this.createFetchOptions();
    return this.http.get(targetUrl, fetchOptions).toPromise();
  }

  base_subscription(subscriptionId: string) {
    const targetUrl = apiUrl(`subscriptions/${subscriptionId}`);
    const fetchOptions = this.createFetchOptions({
      intercept: true,
    });
    return this.http.get(targetUrl, fetchOptions).toPromise();
  }

  health(subscriptionId: string, instanceId: string = "", productType: string = "") {
    let targetUrl = apiUrl(
      `health/${productType && productType === "Wireless" ? `wireless/` : ""}${subscriptionId}${
        instanceId && instanceId.length > 0 ? `/${instanceId}` : ""
      }`,
    );

    const fetchOptions = this.createFetchOptions();
    return this.http
      .get<HealthIndicator>(targetUrl, fetchOptions)
      .pipe(map((data) => Object.assign(new HealthIndicator(), data)))
      .toPromise();
  }

  set_customer_description(customerGUID: string, subscriptionId: string, value: string) {
    let targetUrl = apiUrl("customers/" + customerGUID + "/descriptions/" + subscriptionId);
    const fetchOptions = this.createFetchOptions();
    const parameters = {
      customerId: customerGUID,
      subscriptionId,
      description: value,
    };
    console.log(parameters);

    if (value === "") {
      console.log("Deleting the customer description");
      return this.http.delete(targetUrl, fetchOptions).toPromise();
    } else {
      console.log("Attempting to modify the description");
      return this.http
        .put(targetUrl, parameters, fetchOptions)
        .toPromise()
        .catch((err) => {
          console.log("Unable to modify changing the description");
          targetUrl = apiUrl("customers/descriptions");
          return this.http.post(targetUrl, parameters, fetchOptions).toPromise();
        });
    }
  }

  /**
   * Send a request to customer support regarding
   * an issue, a request for creation or a request
   * for termination.
   *
   * @param customerGUID
   * @param fulfilmentData
   * @return Promise
   */
  add_new_fulfilment_request(customerGUID: string, fulfilmentData: string) {
    const targetUrl = apiUrl("fulfilment/request/" + customerGUID);
    const fetchOptions = this.createFetchOptions({}, "text");
    return this.http.post(targetUrl, fulfilmentData, fetchOptions).toPromise();
  }

  /**
   * Send a request to customer support to report a problem
   *
   * @param customerGUID
   * @param fulfilmentData
   * @return Promise
   */
  add_new_fulfilment_problem(customerGUID: string, fulfilmentData: string) {
    const targetUrl = apiUrl("fulfilment/request/" + customerGUID);
    const fetchOptions = this.createFetchOptions({}, "text");

    let promise = new Promise((resolve, reject) => {
      this.http
        .post(targetUrl, fulfilmentData, fetchOptions)
        .toPromise()
        .then(
          (res) => {
            // Success
            resolve(true);
          },
          (msg) => {
            // Error
            if (msg.status === 422) {
              if (msg.error.detail) {
                reject(msg.error.detail[0].msg);
              }
            }
            reject(msg);
          },
        );
    });

    return promise;
  }

  /**
   * Upload a fulfilment attachment
   *
   * @param customerGUID
   * @param file
   * @return Promise
   */
  async upload_fulfilment_attachment(customerGUID: string, file: File) {
    const targetUrl = apiUrl("fulfilment/request/" + customerGUID + "/upload-attachment");
    const fetchOptions = this.createFetchOptions({}, "text", "json", {}, "");

    const formData = new FormData();
    formData.append("attachment", file, file.name);

    return await this.http.post(targetUrl, formData, fetchOptions).toPromise();
  }

  /**
   * Get notifications for the current customer.
   *
   * @param customerId
   * @param start cursor
   * @param end cursor/'oldest'/'newest'
   * @return Promise
   */
  messages(
    customerId: string,
    productType: string,
    subscriptionId: string,
    start: Date,
    end: Date,
  ): Promise<SurfNotification[]> {
    let targetUrl = "";

    if (subscriptionId !== "") {
      targetUrl = apiUrl(`messages/subscription/${subscriptionId}`);
    } else {
      targetUrl = apiUrl(`messages/${customerId}`);
      if (productType !== "") {
        targetUrl = targetUrl + "/" + productType;
      }
    }

    const fetchOptions = this.createFetchOptions();

    // force start and and of the day
    start.setHours(0, 0, 0, 0);
    end.setHours(23, 59, 59, 999);

    // convert dates to UTC
    const startUTC = new Date(start.getTime() - start.getTimezoneOffset() * 60000);
    const endUTC = new Date(end.getTime() - end.getTimezoneOffset() * 60000);
    const startString = Math.round(startUTC.getTime() / 1000);
    const endString = Math.round(endUTC.getTime() / 1000);

    const offsetUrlPart = `?startTimestamp=${startString}&endTimestamp=${endString}&active=false`;

    return this.http
      .get<[]>(targetUrl + offsetUrlPart, fetchOptions)
      .pipe(
        map((data) => {
          let rv = [];
          if (data !== undefined && data.length > 0) {
            rv = data.map((obj) => Object.assign(new SurfNotification(), obj));
          }
          // console.log(data);
          return rv;
        }),
        tap((results: SurfNotification[]) => {
          results.sort((a, b) => (a.start_timestamp < b.start_timestamp ? 1 : -1));
        }),
      )
      .toPromise<SurfNotification[]>();
  }

  /**
   * Get notifications of a subscription.
   *
   * @param subscriptionId
   * @return Promise
   */
  messagesBySubscription(subscriptionId: string, activeMsgs: boolean): Promise<SurfNotification[]> {
    if (subscriptionId === undefined) {
      return;
    }
    const targetUrl = apiUrl(`messages/subscription/${subscriptionId}`);

    const fetchOptions = this.createFetchOptions({
      observe: "body",
    });
    fetchOptions["params"] = { active: activeMsgs };
    return this.http
      .get<[]>(targetUrl, fetchOptions)
      .pipe(
        map((data) => {
          let rv = [];
          if (data !== undefined && data.length > 0) {
            rv = data.map((obj) => Object.assign(new SurfNotification(), obj));
          }
          // console.log(data);
          return rv;
        }),
      )
      .toPromise<SurfNotification[]>();
  }

  blog() {
    const targetUrl = apiUrl("messages/blog/feed");
    const fetchOptions = this.createFetchOptions({ responseType: "text" });
    return this.http
      .get<string>(targetUrl, fetchOptions)
      .pipe(
        map((data) => {
          const x = new DOMParser();
          const y = x.parseFromString(data, "application/xml");
          return y;
        }),
      )
      .toPromise();
  }

  customers() {
    const targetUrl = apiUrl("customers/");
    const fetchOptions = this.createFetchOptions();
    return this.http
      .get<Customer[]>(targetUrl, fetchOptions)
      .pipe(
        map((data) => {
          const rv = [];
          // eslint-disable-next-line @typescript-eslint/prefer-for-of
          for (let i = 0; i < data.length; i++) {
            rv.push(Object.assign(new Customer(), data[i]));
          }
          return rv;
        }),
      )
      .toPromise();
  }

  selfServiceCommand(cmd: SelfServiceCommand): Promise<ModifyResponse> {
    const targetUrl = apiUrl(`fulfilment/subscription${cmd.uri}`);
    const fetchOptions = this.createFetchOptions({}, {}, null, {
      access_token:
        cmd.uri.includes("email") ? localStorage.getItem("access_token") : localStorage.getItem("strong_access_token"),
    });
    return this.http.post<ModifyResponse>(targetUrl, cmd.json, fetchOptions).toPromise();
  }

  updateAutoMitigation(params: any) {
    const targetUrl = apiUrl("ipam/autoMitigation");
    const fetchOptions = this.createFetchOptions({ observe: "body" }, {}, null, {
      access_token: localStorage.getItem("strong_access_token"),
    });
    return this.http.put<ipamSettings>(targetUrl, params, fetchOptions).toPromise();
  }

  getAutoMitigation(subscriptionId: string) {
    const targetUrl = apiUrl(`ipam/autoMitigation/${subscriptionId}`);
    const fetchOptions = this.createFetchOptions({ observe: "body" });
    return this.http.get<AutoMitigation>(targetUrl, fetchOptions).toPromise();
  }

  /**
   * Set minimal_impact_notification.
   *
   * @return Promise
   */
  set_minimal_impact_notification(customerGUID: string, subscriptionId: string, impact: string) {
    let targetUrl = apiUrl("minimal_impact_notifications/");
    const fetchOptions = this.createFetchOptions();
    const parameters = {
      customerId: customerGUID,
      subscriptionId,
      impact,
    };
    return this.http.post(targetUrl, parameters, fetchOptions).toPromise();
  }

  getContactPersons(customerId: string) {
    const targetUrl = apiUrl(`customers/${customerId}/contact_persons`);
    const fetchOptions = this.createFetchOptions({ observe: "body" });
    return this.http.get<any[]>(targetUrl, fetchOptions).toPromise();
  }

  getSurfCertFilters(address: string, prefixLength?: string) {
    const targetUrl = apiUrl(`certFilter/${address}${prefixLength ? `/${prefixLength}` : ""}`);
    const fetchOptions = this.createFetchOptions({ observe: "body" });
    return this.http.get(targetUrl, fetchOptions).toPromise();
  }

  ddosFilteredTraffic(subscriptionId: string, params: any) {
    const queryParameters = Object.entries(params)
      .map((entry) => `${entry[0]}=${entry[1]}`)
      .join("&");
    const targetUrl = apiUrl(`stats/ddos/${subscriptionId}?${queryParameters}`);
    const fetchOptions = this.createFetchOptions({ observe: "body" });
    return this.http.get<DdosFilteredTraffic[]>(targetUrl, fetchOptions).toPromise();
  }
}
