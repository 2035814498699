import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { ApiHelper } from "../../../helpers/apihelper";
import { SubscriptionService } from "../../../services/subscriptionservice";
import { LinkMemberDetailsType, ProductType } from "../../../gql/generated-ingestor";
import { AddressType, Scalars } from "../../../gql/generated-ingestor";
import { StorageHelper } from "../../../helpers/storage/storagehelper";
import { getDetailRouterLink } from "../../../helpers/getDetailRouterLink";

export interface ServiceType {
  subscriptionId?: string;
  customerId?: string;
  status?: string;
  description: string;
  customerDescription?: string;
  insync?: boolean;
  organisation?: string;
  percentage?: number;
  speed?: number;
  vlanrange?: string;
  portSpeed?: number;
  amountReserved?: number;
  product?: ProductType;
  services?: ServiceType[];
}

export interface PortTileSap {
  organisation?: string;
  vlanrange: string;
  tag: string;
  port?: {
    tags: string[];
    subscriptionId: Scalars["ID"];
    portMode: string;
    customer?: {
      customerId: string;
    };
    customerDescription: string;
    description: string;
    portSpeed: number;
    services: ServiceType[];
    amountReserved?: number;
    address?: Partial<AddressType>;
  };
}

@Component({
  selector: "port-tile",
  templateUrl: "port-tile.html",
  styleUrls: ["../port-visualiser/port-visualiser.scss"],
})
export class PortTile implements OnChanges {
  @Input() service: LinkMemberDetailsType;
  @Input() sap: PortTileSap; // based on ServiceAttachPointType
  @Input() serviceId: string;
  @Input() demoShowVisual = false;
  @Input() healthState = "";
  public showVisual = true;
  public portMode = "Link Member"; // @todo: logic for when this is used with a regular port (in connection.ts)
  public portOwner = "-";
  public customerDescription = "";

  constructor(
    private api: ApiHelper,
    private subscriptionService: SubscriptionService,
  ) {}

  get serviceTag(): string {
    return this.portMode === "Link Member" ? (this.service as any).serviceTag : (this.sap as any).serviceTag;
  }

  routerLinkForService(service: ServiceType): string[] {
    return [getDetailRouterLink(service, service.product.type)];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["service"]) {
      this.portMode = "Link Member";
      this.portOwner = this.service.organisation;
      this.showVisual = false;
      this.subscriptionService.getHealthDataForSubscription(this.service.subscriptionId).then((status) => {
        this.healthState = status.status;
      });
      this.customerDescription = this.service.customerDescription;
    } else if (changes["sap"]) {
      this.portMode = this.sap.port.portMode;
      this.portOwner = this.sap.organisation;
      this.showVisual = this.sap.port.customer.customerId === StorageHelper.currentUser;
      this.customerDescription = this.sap.port.customerDescription;
      if (this.sap.port.address === null) {
        this.sap.port.address = {
          street: "street",
          city: "city",
        };
      }
    }
  }
}
