<div class="card card--no-padding service service--clickable" [routerLink]="[getRouterLink()]">
  <div class="service__header">
    <div class="service__left">
      <svg-icon
        *ngIf="subscription.name === 'SURFwireless'"
        class="service-icon-wireless"
        src="assets/images/icons/wireless.svg"
      ></svg-icon>
      <svg-icon
        *ngIf="subscription.name !== 'SURFwireless'"
        src="assets/images/icons/status-{{ subscription?.health?.status | status_to_icon }}.svg"
      ></svg-icon>
      <div class="service__header-caption">
        <div class="service__title-wrapper">
          <h3 class="service__title" *ngIf="!isEditable">
            {{ subscription.customerDescription }}
            <div class="service__edit-icon" (click)="edit()">
              <svg-icon src="assets/images/icons/edit.svg"></svg-icon>
            </div>
          </h3>
          <div *ngIf="isEditable" class="alias__edit-form">
            <input
              [(ngModel)]="temporaryName"
              (click)="stop()"
              (blur)="saveCustomerDescription()"
              class="input"
              type="text"
              value="{{ subscription.customerDescription }}"
            />
            <span (click)="saveCustomerDescription()" class="alias__edit-button alias__edit-button--save">
              <svg-icon src="assets/images/icons/tick.svg"></svg-icon>
            </span>
            <span
              (click)="cancelEditingCustomerDescription($event)"
              class="alias__edit-button alias__edit-button--cancel"
            >
              <svg-icon src="assets/images/icons/x.svg"></svg-icon>
            </span>
          </div>
        </div>
        <div class="service__subtitle">
          <span class="black">{{ subscription.subscriptionId | shorten_guid }}</span>
          -
          <span class="black"
            >{{ "ServiceElement.Owner" | translate }}: {{ subscription?.organisation || subscription?.customer?.fullname
            }}</span
          ><br />
          <span class="service__endpoint" *ngFor="let location of (subscription?.locations ?? [])">
            <span *ngIf="location.address.city">
              {{ location.address.city.charAt(0).toUpperCase() + location.address.city.slice(1).toLowerCase() }} ({{
              location.address.street }} {{ location.address.number }})</span
            >
          </span>
        </div>
        <div class="tags">
          <div *ngIf="subscription?.firewallEnabled === true" class="tag tag--firewall">
            <svg-icon src="assets/images/icons/fw.svg"></svg-icon>
          </div>
          <div *ngFor="let tag of subscription.tags" class="tag">{{ tag }}</div>
        </div>

        <div *ngIf="subscription.name === 'SURFwireless'" class="service__access-points">
          <div class="service__access-points-item">
            <span>{{ 'ServiceElement.APsActive' | translate }}</span>
            <span *ngIf="wirelessKpiList && wirelessKpiList.activeAps !== null">{{ wirelessKpiList.activeAps }}</span>
            <span *ngIf="wirelessKpiList?.activeAps === null">-</span>
          </div>
          <div class="service__access-points-item">
            <span>{{ 'ServiceElement.APsInactive' | translate }}</span>
            <span *ngIf="wirelessKpiList && wirelessKpiList.inactiveAps !== null"
              >{{ wirelessKpiList.inactiveAps }}</span
            >
            <span *ngIf="wirelessKpiList?.inactiveAps === null">-</span>
          </div>
        </div>
      </div>
    </div>
    <div class="service__right">
      <div class="service__button" (click)="openIncidentDialog($event)">
        {{ 'Services.Fulfilment.ReportProblem' | translate }}
      </div>
      <div *ngIf="subscription.product.type === 'Wireless'">
        <ng-container [ngSwitch]="subscription?.sensorPresent">
          <loader *ngSwitchCase="undefined" [small]="true" [paddingSize]="'small'"></loader>
          <ng-container *ngSwitchCase="true">
            <kpi-row
              *ngIf="wirelessKpiList !== undefined"
              [healthData]="wirelessKpiList"
              [vendor]="wirelessVendor"
            ></kpi-row>
            <loader *ngIf="wirelessKpiList === undefined" [small]="true" [paddingSize]="'small'"></loader>
          </ng-container>
          <p *ngSwitchCase="false">No sensor present</p>
          <p *ngSwitchDefault>Unknown sensor state</p>
        </ng-container>
      </div>
      <div class="service__current" *ngIf="subscription?.health?.traffic && subscription?.product?.type !== 'Wireless'">
        <div class="service__current-col">
          <div class="service__current-title">{{ 'ServiceElement.Chart.NowIn' | translate }}</div>
          <div>{{ subscription.health.traffic.last.in | readable_size }}</div>
        </div>
        <div class="service__current-col">
          <div class="service__current-title">{{ 'ServiceElement.Chart.NowOut' | translate }}</div>
          <div>{{ subscription.health.traffic.last.out | readable_size }}</div>
        </div>
      </div>
    </div>
  </div>

  <div class="service__graph">
    <port-visualiser *ngIf="productType === 'Port' && !subscription?.tags?.includes('LINK_MEMBER')" [subscription]="subscription"></port-visualiser>
  </div>

  <service-status
    *ngIf="subscription.product.type !== 'Wireless'"
    class="service__status"
    productType="{{productType}}"
    subscriptionId="{{subscription.subscriptionId}}"
  >
  </service-status>
</div>

<ng-template #problemModal>
  <div class="dialog" style="min-height: 0px !important">
    <div class="dialog__header">
      <h3 class="dialog__title">{{ 'Services.Fulfilment.ReportProblem' | translate }}</h3>
      <svg-icon src="assets/images/icons/x.svg" class="dialog__close" (click)="closeDialog()"></svg-icon>
    </div>

    <div class="dialog__body dialog__body--grow">
      <div class="row">
        <div class="col-12">
          <label for="" class="form-label">{{ 'ServiceElement.SURFnetDescription' | translate }}</label>
          <div class="input__wrapper">
            <input type="text" class="input" value="{{ subscription.description }}" disabled />
          </div>
          <label for="" class="form-label">UUID</label>
          <div class="input__wrapper">
            <input type="text" class="input" value="{{ subscription.subscriptionId }}" disabled />
          </div>
          <label for="" class="form-label">{{ 'Global.Email' | translate }}</label>
          <div class="input__wrapper">
            <input type="text" class="input" placeholder="{{ 'Global.Email' | translate }}" />
          </div>
          <label for="" class="form-label">{{ 'Global.Message' | translate }}</label>
          <div class="textarea__wrapper">
            <textarea class="textarea" placeholder="{{ 'Global.Message' | translate }}"></textarea>
          </div>
        </div>
      </div>
    </div>

    <div class="dialog__footer dialog__footer--end">
      <div class="button__flex">
        <span class="button button--secondary">{{ 'Global.Action.Cancel' | translate }}</span>
        <span class="button">{{ 'Global.Action.Confirm' | translate }}</span>
      </div>
    </div>
  </div>
</ng-template>
