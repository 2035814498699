<div class="page-tab">
  <div class="page-tab__dividers">
    <span></span>
    <span></span>
    <span></span>
  </div>
  <div class="page-tab__buttons">
    <button class="page-tab__button" (click)="tab('config')" [ngClass]="{'active': activeTab === 'config' }">
      {{ 'Tabs.ConfigAndStats' | translate }}
    </button>
    <button
      *ngIf="productType === 'FW'"
      class="page-tab__button"
      (click)="tab('system-stats')"
      [ngClass]="{'active': activeTab === 'system-stats' }"
    >
      {{ 'Tabs.SystemStats' | translate }}
    </button>
    <button
      class="page-tab__button"
      (click)="tab('graphic-view')"
      [ngClass]="{'active': activeTab === 'graphic-view' }"
    >
      {{ 'Tabs.GraphicView' | translate }}
    </button>
  </div>
</div>
