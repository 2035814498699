import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, AfterViewInit } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { FlickityDirective } from "ngx-flickity";
import { MatomoTracker } from "ngx-matomo";
import { AuthService } from "../../../services/AuthService";

// For Flickity
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { isPlatformServer } from "@angular/common";
import { ApiHelper } from "../../../helpers/apihelper";
import { TranslateService } from "@ngx-translate/core";
@Injectable()
export class AppConfigService {
  constructor(@Inject(PLATFORM_ID) private platformId: any) {}

  isPlatformServer() {
    return isPlatformServer(this.platformId);
  }
}

interface OnboardingScreen {
  imageUrl: Array<string>;
  index: number;
  title: string;
  content: string;
  isFirst?: boolean;
  isLast?: boolean;
}

@Component({
  selector: "dialog-onboarding",
  templateUrl: "dialog-onboarding.html",
})
export class DialogOnboardingComponent implements AfterViewInit {
  @Output() done = new EventEmitter();
  @ViewChild("flickityelement", { static: true }) flickity: ElementRef;

  public dotInfo: Array<any> = [];
  public neverAgain = false;
  public screens: Array<OnboardingScreen>;

  private flickityElement: any = null;

  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<DialogOnboardingComponent>,
    public auth: AuthService,
    public api: ApiHelper,
    public translate: TranslateService,
    private matomo: MatomoTracker,
  ) {
    // this.generateDotInfo();
    matomo.setDocumentTitle("Onboarding");
    const language = translate.currentLang;

    // temporarily hardcode the onboarding contents.
    // eventually this will come from an API call.

    this.screens = [1, 2, 3, 4, 5].map((slide) => ({
      index: slide - 1,
      imageUrl: [`assets/images/onboarding/visual-slide89_${slide}@2x.png`],
      title: this.translate.instant(`Onboarding.Slide89_${slide}.Title`),
      content: this.translate.instant(`Onboarding.Slide89_${slide}.Content`),
      isFirst: slide === 1,
      isLast: slide === 5,
    }));
  }

  closeDialog(): void {
    this.dialogRef.close();
    if (this.neverAgain) {
      this.done.emit();
    }
    this.matomo.trackEvent("Flow", "Skipped", "Onboarding");
  }

  markAsCompleted(): void {
    this.matomo.trackEvent("Flow", "Completed", "Onboarding");
    if (this.neverAgain) {
      this.done.emit();
    }
    this.dialogRef.close();
  }

  generateDotInfo() {
    let activeSlide = 0;
    let slideCount = 2;

    if (this.flickityElement) {
      activeSlide = this.flickityElement.flkty.selectedIndex;
      slideCount = this.flickityElement.flkty.slides.length;
    }

    this.dotInfo = [];

    for (let i = 0; i < slideCount; i++) {
      this.dotInfo.push({ active: i === activeSlide });
    }
  }

  flickityClick(index) {
    if (this.flickityElement) {
      this.flickityElement.flkty.select(index, false, false);
    }

    this.generateDotInfo();
  }

  ngAfterViewInit() {
    this.matomo.trackPageView();
    const acs: AppConfigService = new AppConfigService({});
    this.flickityElement = new FlickityDirective(this.flickity, acs as any);
    this.flickityElement.config = {
      cellAlign: "left",
      imagesLoaded: true,
      pageDots: false,
      prevNextButtons: false,
      draggable: false,
    };
    this.flickityElement.init();
    setTimeout(() => {
      this.generateDotInfo();
    });
  }

  flickityNext() {
    if (this.flickityElement) {
      this.flickityElement.next();
    }

    this.generateDotInfo();
  }
}
