import { Component, Input, SimpleChanges, OnChanges } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "empty-state",
  templateUrl: "empty-state.html",
})
export class EmptyStateComponent implements OnChanges {
  @Input() title: string;
  @Input() icons: Array<string>;

  constructor(private translate: TranslateService) {}

  get translatedTitle() {
    return this.translate.instant("ServiceElement.EmptyState.Title", {
      product: this.title || "data",
    });
  }

  ngOnChanges(changes: SimpleChanges) {}
}
