import { PossibleTypesResultData } from "../gql/possible-types";

const defaultFetchPolicy: any = {
  keyFields: ["subscriptionId"],
  merge: true,
};

const defaultFetchPolicyInstance: any = {
  keyFields: ["subscriptionInstanceId"],
  merge: true,
};

export const createFetchPolicies = (types: PossibleTypesResultData) => {
  function* generateEntries() {
    for (const type of Object.keys(types.possibleTypes)) {
      if (type.endsWith("Subscription")) {
        const policy: any = {
          key: type,
          value: defaultFetchPolicy,
        };
        // if (type === "Sn8L3VpnSubscription") {
        policy.value.fields = {
          vc: {
            // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
            merge(existing, incoming, { mergeObjects }) {
              return mergeObjects(existing, incoming);
            },
          },
          product: {
            // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
            merge(existing, incoming, { mergeObjects }) {
              return mergeObjects(existing, incoming);
            },
          },
          affectedSubscriptions: {
            // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
            merge(existing, incoming, { mergeObjects }) {
              return mergeObjects(existing, incoming);
            },
          },
        };
        // if
        yield policy;
      }
    }
  }

  const rv: any = {};
  [...generateEntries()].map((entry: any) => (rv[entry.key] = entry.value));
  rv["Sn8L3VpnVirtualCircuitBlock"] = defaultFetchPolicyInstance;
  rv["Sn8MscBlock"] = defaultFetchPolicyInstance;
  rv["Sn8ServicePortBlock"] = defaultFetchPolicyInstance;
  rv["ImsCircuit"] = { ...defaultFetchPolicy, keyFields: ["imsCircuitId"] };
  rv["AffectedSubscription"] = { ...defaultFetchPolicy };
  return rv;
};

/**
 * Convert an ArrayBuffer to a HEX string
 *
 * @param buffer inputbuffer to be converted
 * @returns string
 */
const bufferToHex = (buffer: ArrayBuffer): string => {
  const view = new DataView(buffer);

  let hex = "";
  for (let i = 0; i < view.byteLength; i += 4) {
    hex += view.getUint32(i).toString(16).padStart(8, "0");
  }

  return hex;
};

/**
 * Create the SHA-256 hash of a string/Buffer
 *
 * @param input thing to be hashed
 * @returns string
 */
export const sha256 = async (input: any) => {
  // make the input an array or arraybuffer.
  let _in = typeof input === "string" ? new globalThis.TextEncoder().encode(input) : input;
  const hash = await window.crypto.subtle.digest("SHA-256", _in);

  return bufferToHex(hash);
};
