import { Component, OnInit } from "@angular/core";
import { Apollo } from "apollo-angular";
import { MatomoTracker } from "ngx-matomo";
import { PageInfo } from "../../gql/generated";
import { CustomerWithSubscriptionIds } from "../../graphql/custom-types";
import { SUBSCRIPTIONS_OF_TYPE_QUERY } from "../../graphql/domain/subscription";
import { AIRT_SCAN_QUERY } from "../../graphql/domain/airtscan";
import { SearchFilter } from "../../gql/generated";
import { Sort } from "@angular/material/sort";
import { AuthService } from "../../services/AuthService";

@Component({
  selector: "page-cert",
  templateUrl: "cert.html",
  styleUrls: ["cert.scss"],
  //changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CertPage implements OnInit {
  public activeTab = "scan";
  public subscriptions: any[] = [];
  public airtScans: any[] = [];
  public searchFilter: SearchFilter = {};
  public loading = true;
  public pageInfo: PageInfo;
  public prefixes: string[] = [];

  private paginationAfter = 0;
  private sortInfo: Sort;

  constructor(
    private matomo: MatomoTracker,
    private apollo: Apollo,
    private auth: AuthService,
  ) {
    this.matomo.setDocumentTitle("Cert");
  }

  // get prefixes(): string[] {
  //   return this.subscriptions.map((subscription) => subscription.prefix);
  // }

  ngOnInit() {
    this.matomo.trackPageView();
    this.fetchPrefixSubscriptions();
    if (this.auth.hasPendingStrongAction("autoMitigation")) {
      this.changeTab("ddos");
    }
  }

  fetchPrefixSubscriptions() {
    this.apollo
      .query<CustomerWithSubscriptionIds>({
        query: SUBSCRIPTIONS_OF_TYPE_QUERY,
        errorPolicy: "all",
        variables: {
          customer: localStorage.getItem("viewingCustomerGUID"),
          products: ["IP_PREFIX"],
          status: ["active", "migrated"],
          portServices: false,
        },
      })
      .subscribe(({ data }) => {
        this.subscriptions = [];
        data.customer.subscriptions?.forEach((sub) => {
          const prefix = sub?.ipPrefix?.ipam?.prefix;
          if (prefix) {
            this.subscriptions.push({
              id: sub.subscriptionId,
              prefix,
            });
          }
          this.prefixes = this.subscriptions.map((subscription) => subscription.prefix);
        });
      });
  }

  async fetchAirtScanInformation() {
    this.apollo
      .query<any>({
        query: AIRT_SCAN_QUERY,
        fetchPolicy: "network-only",
        errorPolicy: "all",
        variables: {
          filter: this.searchFilter,
          first: 20,
          after: this.paginationAfter,
          sort: this.buildSort(),
        },
      })
      .subscribe(({ data }) => {
        this.airtScans = data?.airtScans || [];
        this.pageInfo = data?.airtScans?.pageInfo;
      });
  }

  changeTab(tab: string) {
    this.activeTab = tab;
  }

  onFiltersChanged(event: any) {
    this.searchFilter = event;
    this.fetchAirtScanInformation();
  }

  onPaginationChanged(event: number) {
    this.paginationAfter = event > -1 ? event : 0;
    this.fetchAirtScanInformation();
  }

  onSortingChanged(event: any) {
    this.sortInfo = event;
    this.fetchAirtScanInformation();
  }

  buildSort() {
    return {
      order: [{ [this.sortInfo?.active || "ip"]: this.sortInfo?.direction?.toUpperCase() || "ASC" }],
    };
  }
}
